import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, shareReplay } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Web3Settings } from '../common/models';
import { FAQ } from '../feature/faq/faq-item.model';
import { ChartApiData } from '../feature/dashboard/dashboard/chart/models/chart.model';
import Helper, { CACHE_KEYS } from '../common/helpers/helper';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private readonly apiUrl = `${environment.apiUrl}/settings`;
  private readonly web3Settings$: Observable<Web3Settings>;
  private readonly faqItems$: Observable<FAQ>;

  constructor(private readonly http: HttpClient) {
    this.web3Settings$ = this.createCachedRequest<Web3Settings>(
      `${this.apiUrl}/web3Settings`,
      CACHE_KEYS.WEB3,
    );
    this.faqItems$ = this.createCachedRequest<FAQ>(
      `${this.apiUrl}/faq`,
      CACHE_KEYS.FAQ,
    );
  }

  getWeb3Settings(): Observable<Web3Settings> {
    const cache = Helper.getCacheItem<Web3Settings>(CACHE_KEYS.WEB3);
    return cache && Helper.isValidCache(cache)
      ? of(cache.data)
      : this.web3Settings$;
  }

  getFaqItems(): Observable<FAQ> {
    const cache = Helper.getCacheItem<FAQ>(CACHE_KEYS.FAQ);
    return cache && Helper.isValidCache(cache)
      ? of(cache.data)
      : this.faqItems$;
  }

  getChartData(period: string): Observable<ChartApiData[]> {
    const params = new HttpParams().set('period', period);
    return this.http.get<ChartApiData[]>(`${this.apiUrl}/chart`, { params });
  }

  private createCachedRequest<T>(url: string, cacheKey: string): Observable<T> {
    return this.http.get<T>(url).pipe(
      tap((data) => Helper.setCacheItem(cacheKey, data)),
      shareReplay(1),
    );
  }
}
